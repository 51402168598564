import React from "react";
import { observer } from "mobx-react";
import { Box, Checkbox, Typography } from "@material-ui/core";
import MediaButton from "../../lib/components/MediaButton";
import AppStore from "../../store/AppStore";
import { JumpMenu } from "../../lib/rio/JumpMenu";
import { css } from "glamor";
import WarningIcon from "@material-ui/icons/Warning";
import MemoPlayer from "../MemoPlayer";
import { ScrollableView } from "../../lib/rio/ScrollableView";
import Player from "../../lib/audiox/models/Player";
import VinylMediaButton from "../../lib/components/VinylMediaButton";
import MidiMediaButton from "../../lib/components/MidiMediaButton";
import { MicrophoneOption } from "../../lib/audiox/models/Microphone";

interface StudioType {
  store: AppStore;
}
const Studio: React.FC<StudioType> = observer(({ store }) => {
  const booth = store.recordingBooth;
  const [liveFeedback, setLiveFeedback] = React.useState(false);
  const options = booth.microphone.microphoneOptions.map(
    (option: MicrophoneOption) => ({
      value: option.value,
      label: option.label,
      action: () => {
        booth.microphone.onMicrophoneChange(option);
      },
    }),
  );
  return (
    <ScrollableView id="recording-booth" topOffset={100}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Box marginTop="24px" marginBottom="24px">
          <div>
            <div
              {...css({
                fontWeight: "bold",
                letterSpacing: 0,
                marginBottom: "4px",
              })}
            >
              Select microphone
            </div>
            <JumpMenu
              width="300px"
              label="Select microphone"
              selectedId={booth.microphone.currentMicrophone?.value}
              options={options}
            />
          </div>
        </Box>
        <Box display="flex" alignItems="center">
          <MediaButton
            record={true}
            isPlaying={booth.recorder.recording}
            onToggle={() => {
              if (booth.recorder.recording) {
                booth.stopRecording();
              } else {
                booth.startRecording(liveFeedback);
              }
            }}
          />
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={liveFeedback}
              onClick={() => {
                booth.audioSource.toggleLiveFeedback(!liveFeedback);
                setLiveFeedback(!liveFeedback);
              }}
            />
            <Typography>Live feedback</Typography>
          </Box>
        </Box>

        <div
          {...css({
            width: "280px",
            marginTop: "24px",
            color: "#737373",
          })}
        >
          <div
            style={{
              color: "red",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gridGap: "3px",
              fontWeight: "bold",
            }}
          >
            <WarningIcon />
            <span>Warning!</span>
          </div>

          <Typography style={{ fontSize: "11px" }}>
            If you opt to leave live feedback on, make sure you have
            headphones on or your speakers muted or this will blow
            your ears.
          </Typography>
        </div>
        <canvas id="visualizer" width={200} height={70} />

        <Box marginTop="40px">
          {booth.currentVoiceMemos.map((voiceMemo: any, i: any) => {
            const player: Player = voiceMemo.player;

            return <MemoPlayer key={`memo-${i}`} player={player} />;
          })}
        </Box>
      </Box>
    </ScrollableView>
  );
});
export default Studio;
