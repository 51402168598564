import React from "react";
import { css, keyframes } from "glamor";
import { defaultStyles, FontFamily, glow } from "../libs/styles";
import AppStore from "../store/AppStore";
import { inject, observer } from "mobx-react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Studio from "./booth/Studio";

const FADE_TIME = 500;

const fadeIn = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const fadeOut = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 },
});

const styles = {
  container: {},
  body: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
};
interface RecordingBoothType {
  store: AppStore;
}
const RecordingBooth: React.FC<RecordingBoothType> = ({ store }) => {
  const [animation, setAnimation] = React.useState(fadeIn);
  const closeRecording = () => {
    setAnimation(fadeOut);
    window.location.href = "/";
  };
  return (
    <div
      {...css(styles.container, {
        animation: `${animation} ease ${FADE_TIME}ms`,
      })}
    >
      <div {...css(styles.body)}>
        <h1
          {...css(defaultStyles.font.h1, {
            fontFamily: FontFamily.lobster,
            fontSize: "48px",
            ...glow("white", "red"),
          })}
        >
          Recording Booth
        </h1>
        <div {...css(defaultStyles.font.body, { maxWidth: "450px" })}>
          <Studio store={store} />
        </div>
      </div>
      <a
        {...css(defaultStyles.button.primary, {
          position: "fixed",
          bottom: "24px",
          right: "24px",
        })}
        onClick={() => {
          closeRecording();
        }}
      >
        <span>Leave Recording Booth</span>
        <ExitToAppIcon />
      </a>
    </div>
  );
};

export default inject("store")(observer(RecordingBooth));
